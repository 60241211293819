if (typeof document.forms.form === "object") {
  let element = document.forms.form.elements;

  for (let i = 0; i < element.length; i++) {
    let childEM =
      element[i].parentElement.parentElement.parentElement.firstChild
        .childNodes[1];

    if (childEM) {
      if (childEM.classList.contains("zf-important")) {
        element[i].required = true;
      }
    }
  }
}
